@use "./variables" as *;

@use "~bulma/sass/base/generic";
@use "~bulma/sass/base/minireset";
@use "~bulma/sass/base/skeleton";
@use "~bulma/sass/components/dropdown";
@use "~bulma/sass/components/navbar";
@use "~bulma/sass/elements/button";
@use "~bulma/sass/elements/icon";
@use "~bulma/sass/elements/notification";
@use "~bulma/sass/elements/title";
@use "~bulma/sass/form/input-textarea";
@use "~bulma/sass/form/select";
@use "~bulma/sass/form/tools";
@use "~bulma/sass/helpers/float";
@use "~bulma/sass/helpers/spacing";
@use "~bulma/sass/themes/light";
@use "~bulma/sass/themes/setup";

:root {
  @include light.light-theme;
  @include setup.setup-theme;
}

.dropdown-menu {
  z-index: 101; /* Higher than audio player */
}

.dropdown-content {
  background-color: white;
}

.input, .button {
  border-color: $border-gray;
  color: $text-gray;

  &:focus {
    border-color: $control-gray;
    box-shadow: none;
  }
}

.dropdown-item {
  cursor: pointer;
  text-decoration: none !important;
  line-height: 1.8rem !important;
  background: none !important;

  &.a {
    padding-inline-end: none !important;
  }

  &:hover {
    background-color: rgba($blue, 0.3) !important;
  }
}

select {
  color: $title-gray !important;
}

.section {
  padding: 1rem;
}

.content {
  padding: none !important;
  margin-top: 2rem;
  margin: 2rem auto;
  max-width: 1200px !important;

  hr {
    border: 1px solid $header-gray;
  }

  p {
    margin-bottom: 0.5rem !important;
    font-size: 1.2rem;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $title-gray;
  }

  h2 {
    font-size: 1.7rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $title-gray;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    margin-top: 3rem !important;
    color: $title-gray;

    &::before {
      font-size: 1.1rem;
      content: "🍩";
      margin-right: 0.4rem;
      filter: grayscale(100%);
    }
  }

  h3 + h3,
  h3 ~ h3 {
    margin-top: 2rem;
  }
}
