@use "./variables" as *;

@use "./bulma-custom";
@use "./content";
@use "./eras";
@use "./errors";
@use "./feedback";
@use "./likes";
@use "./map";
@use "./mobile";
@use "./modal";
@use "./navbar";
@use "./pagination";
@use "./player";
@use "./playlist";
@use "./search";
@use "./tags";
@use "./tooltips";

@import "~@fontsource/marck-script/400.css";
@import "~@fontsource/open-sans-condensed/300.css";
@import "~@fontsource/open-sans-condensed/700.css";
@import "~mapbox-gl/dist/mapbox-gl.css";

html, body {
  margin: 0;
  background-color: $bg-gray;
  font-family: "Open Sans Condensed", sans-serif;
}

/* Disable gray squares after taps on mobile */
* {
  -webkit-tap-highlight-color: transparent;
}

.root-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

input, textarea, select, button {
  font-family: "Open Sans Condensed", sans-serif;
}

button {
  border: none !important;
  color: $text-gray !important;
}

a {
  color: $text-gray;
  text-decoration: none !important;
}

::placeholder {
  font-family: "Open Sans Condensed", sans-serif;
}

.select::after {
  border-color: $border-gray !important; /* Bulma dropdown chevron color */
}

.label {
  color: $text-gray;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem !important;
}

.context-dropdown .button {
  background-color: transparent;
  box-shadow: none;

  &:hover {
    background-color: $control-gray;
  }
}
.context-nudge-right {
  position: relative;
  left: 6rem;
}

.text-gray {
  color: $text-gray;
}

.context-dropdown-content {
  .icon {
    margin-right: 0.4rem;
    position: relative;
    top: 3px;
    color: $text-gray;
    width: 1rem;
  }
  .dropdown-item {
    font-size: 1.2rem;
    padding-inline-end: 1.2rem;
  }
}

.excerpt {
  font-style: italic;
}

.excerpt-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0.2;
  font-size: 1rem;
  z-index: 1;
  pointer-events: none;
}

.large-album-art {
  img {
    border-radius: $radius;
    margin-top: 0.5rem;
  }
}

.background-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(100px);
  opacity: 0.8;
  z-index: 0;
  pointer-events: none;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;

  .loader-content {
    transform: translateY(-100px);
  }
}

.hilite {
  background-color: transparent !important;
  box-shadow: 0px 4px 0px $highlight-orange !important;
}

.hilite-badge {
  background-color: $highlight-orange !important;
}

.section {
  padding-top: 0 !important;
}

.api-command {
  margin: 0.5rem 0;
  display: block;
  border-radius: 0.25rem;
  border: 1px dashed #ccc;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #ffffff;
}
.api-inline {
  margin: 0.5rem;
  display: inline-block;
  border-radius: 0.25rem;
  border: 1px dashed #ccc;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #ffffff;
}
.api-doc {
  display: block;
  margin: 0 0 2rem 1rem;
}

.external-login-btn {
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
}
.login-logo {
  background-color: white;
  border-radius: 3px;
  width: 28px;
  height: 28px;
  position: relative;
  left: -10px;
  padding: 3px;

  img {
    position: relative;
    top: -3px;
  }
}
.google-btn {
  color: white !important;
  background-color: $google-blue;
}
