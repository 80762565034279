@use "./variables" as *;

main {
  flex: 1;
}

#layout-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px !important;
  width: 100%;
  margin: 0 auto;
}

#main-content {
  flex: 1;
  padding-bottom: 1rem;
}

#sidebar {
  background-color: $navbar-gray;
  padding: 1rem;
  flex: 0 0 16rem;
  border-radius: $radius;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  max-height: fit-content;
  z-index: 2;
  opacity: 1;

  .sidebar-content {
    select {
      max-width: 20rem;
    }

    hr {
      height: 1px;
      background-color: $text-gray;
      opacity: 0.2;
      margin-top: 1rem;
      margin-bottom: 0.8rem;
    }

    .sidebar-title {
      font-size: 2rem;
      font-weight: bold;
      line-height: 2rem;
      margin-bottom: 0.4rem;
    }

    .sidebar-info {
      font-size: 1.2rem;
      margin-top: 0.3rem;
      color: $title-gray;

      a {
        color: $title-gray !important;
      }
    }

    .show-duration {
      margin-top: 0.3rem;
    }

    .sidebar-subtitle {
      font-size: 1.3rem;
      font-weight: bold;
      color: $title-gray;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }

    .sidebar-detail {
      font-size: 1.3rem;
      line-height: 1.7rem;
      margin-top: 1rem;
    }

    .sidebar-filters {
      margin-top: 1rem;

      .select, .input {
        margin-top: 0.8rem;
        margin-right: 0.8rem;
      }
    }

    .sidebar-control-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0rem;
    }

    .taper-notes:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .sidebar-map {
      height: 20rem;
    }
  }
}

.sidebar-content {
  display: block;
}

.loading-shimmer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(240, 240, 240, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(240, 240, 240, 0) 100%);
  animation: shimmer 1.5s infinite;
}

.cover-art-modal-trigger {
  cursor: pointer;

  &:hover {
    img {
      filter: grayscale(0.5);
    }
  }
}

.cover-art {
  border-radius: $radius;
  background-color: $inactive-gray;
  position: relative;
  overflow: hidden;

  img {
    border-radius: $radius;
  }
}

.cover-art-small {
  width: 40px;
  height: 40px;
  border-radius: $radius;
  object-fit: cover;
  position: relative;
  border-radius: 0.3rem !important;
}

.mobile-show-image {
  .cover-art {
    display: block;
    width: 148px !important;
    height: 148px !important;
  }
}

.section-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 0.3rem;

  &:not(:first-of-type) {
    margin-top: 3rem;
  }

  &:first-of-type {
    margin-top: 0.5rem;
  }

  .title-left {
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 1.3rem;
    color: $title-gray;
  }

  .detail-right {
    font-size: 1rem;
    font-weight: bold;
    color: $text-gray;
    line-height: 1rem;
    color: $title-gray;
  }
}

.list-item-link {
  text-decoration: none;
  color: black;
}

.date-link {
  font-size: 0.9rem;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  min-width: 3.8rem;

  &:hover {
    text-decoration: underline;
  }

  a {
    color: $text-gray !important;
  }
}

.list-item {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.1rem 1rem 0.1rem 0.5rem;
  border-radius: $radius;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  min-height: 3.5rem;
  cursor: pointer;
  background-color: $bg-gray;
  background-size: cover;
  background-repeat: no-repeat;

  &.active-item {
    animation: pulse 3s infinite ease-in-out;
    background-image: none !important;
  }

  .cover-art {
    display: inline-block;
    margin-right: 0.2rem;
  }

  .main-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .leftside-numbering {
    font-family: 'Marck Script', cursive;
    font-size: 1.4rem;
    margin-right: 0.8rem;
    position: relative;
    top: 3px;
    width: 2rem;
    text-align: right;
  }

  .leftside-primary {
    flex-grow: 1;
    font-size: 1.5rem;
    color: $text-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 1rem;
    max-width: 60%;
    padding-right: 1rem;
    display: flex;
    align-items: center;

    .cover-art {
      background-color: transparent;
      flex-shrink: 0;

      .cover-art-small {
        top: 5px;
      }
    }

    .text {
      margin-left: 0.5rem;
      flex-shrink: 1;
    }
  }

  .leftside-secondary {
    font-size: 1.1rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 12rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  .leftside-tertiary {
    font-size: 1.1rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 10rem;
    margin-right: 0.5rem;
  }

  .rightside-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-left: auto;
  }

  .rightside-primary {
    font-size: 1.1rem;
    width: 3.5rem;
    text-align: right;
    overflow: hidden;
    text-overflow: ellisis;
    white-space: nowrap;
    margin-left: 1rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
    padding-right: 0.1rem;
    position: relative;
  }

  .rightside-primary-wide {
    font-size: 1.1rem;
    width: 10rem;
    text-align: right;
    overflow: hidden;
    text-overflow: ellisis;
    white-space: nowrap;
    margin-left: 1rem;
    flex-shrink: 0;
    padding-right: 0.1rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  .rightside-secondary {
    font-size: 1.1rem;
    width: auto;
    text-align: right;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .addendum {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
  }
}

.set-group {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .track-item {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.25rem 1rem;
    margin-bottom: 0rem;
    box-shadow: none;
    min-height: 3.5rem;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    background-size: cover;
    background-repeat: no-repeat;

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 1rem;
      right: 1rem;
      height: 1px;
      background-color: $navbar-gray;
    }

    &:first-of-type {
      border-radius: $radius $radius 0 0;
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }

    &:last-of-type {
      border-radius: 0 0 $radius $radius;
    }

    &:only-of-type {
      border-radius: $radius;
      border: none;

      &::after {
        content: none;
      }
    }

    &.focus {
      &::after {
        background-color: $highlight-blue;
        height: 2px;
      }
    }
  }
}

.view-toggle {
  margin-bottom: 0px !important;

  button {
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.cover-art-inspector-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;

  .cover-art-inspector {
    width: 128px;
    height: 128px;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  .cover-art {
    background-color: transparent !important;
  }
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 1rem;
  list-style: none;
  padding: 0;

  &.limited-width {
    grid-template-columns: repeat(auto-fit, minmax(152px, 256px));
  }

  .grid-item {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: $radius;
    max-width: 436px;
    background-color: $inactive-gray;
  }

  @media (hover: hover) and (pointer: fine) {
    .grid-item:hover {
      opacity: 0.5 !important;
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: rgba(#e5e5e5, 0.85);
    padding: 4px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: $title-gray;
    border-radius: 0 0 $radius $radius;

    .show-date {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2rem;
    }

    .venue-name {
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding: 0 0.3rem;
    }

    .venue-location {
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .period {
      font-weight: bold;
      font-size: 4rem;
      margin-top: -20px;

      &.period-range {
        font-size: 2.5rem;
        line-height: 5.5rem;
      }
    }

    .period-details {
      font-size: 1rem;
      margin-top: -20px;
    }
  }
}

@keyframes pulse {
  0% {
    background-color: $highlight-blue;
  }
  50% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  100% {
    background-color: $highlight-blue;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(35%);
  }
}

@media (hover: hover) {
  .list-item:hover {
    background-color: white;
    background-image: none !important;

    &::before {
      display: none;
    }

    .cover-art-small {
      filter: grayscale(0);
    }
  }
}

@media (max-width: 768px) {
  .leftside-tertiary {
    display: none;
  }
}

@media (min-width: 1024px) {
  .show-duration {
    margin-top: 0.5rem
  }

  .display-mobile-only {
    display: none !important;
  }

  .sidebar-content {
    .cover-art {
      display: block;
      width: 224px !important;
      height: 224px !important;
    }
  }
}

@media (max-width: 1023px) {
  .display-mobile-only {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .view-toggle {
    justify-content: center;

    &:not(:first-child) {
      margin-top: 0px !important;
    }
  }

  .sidebar-control-container {
    position: relative;
    top: 3px;
  }

  #layout-container {
    flex-direction: column;
  }

  #main-content {
    width: 100%;
  }

  #sidebar {
    display: inline-block;
    width: 100%;
    flex: none !important;
    padding: 0.3rem;
    background-color: $navbar-gray;

    .sidebar-content {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      column-gap: 2rem !important;
      row-gap: 5px !important;

      .sidebar-control {
        flex: 0;
      }

      .sidebar-hr {
        display: none;
      }

      form {
        width: 100%;
      }

      select {
        margin: none !important;
      }

      .sidebar-filters {
        margin-top: 0rem !important;

        .select, .input {
          margin-top: 0rem !important;
          margin-right: 0.8rem !important;
        }
      }

      .sidebar-title {
        margin-bottom: 0rem;
        font-size: 1.5rem;

        &.show-cover-title {
          position: relative;
          top: -8px;
        }
      }
      .sidebar-subtitle {
        margin-bottom: 0rem !important;
      }
    }
  }
  .hidden-mobile {
    display: none !important;
  }
}

@media (max-width: 420px) {
  .display-phone-only {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .view-toggle {
    display: flex;
    gap: 0.5rem;
  }

  .grid-view {
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    gap: 0.7rem;

    &.limited-width {
      grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    }
  }

  .leftside-secondary {
    display: none;
  }

  .list-item {
    min-height: 2rem;
    padding: 0.25rem 0.6rem;
  }

  .hidden-phone {
    display: none !important;
  }

  .rightside-primary {
    width: 3rem !important;
    margin-left: 0.5rem !important;
  }

  .rightside-secondary {
    display: none !important;
  }

  #layout-container {
    padding: 0.6rem 0.5rem;
    gap: 0.8rem;

    #sidebar {
      display: none !important;
    }
  }

  .title-left {
    font-size: 1.4rem !important;
  }

  .leftside-primary {
    font-size: 1.6rem !important;
    padding-right: 0.1rem !important;
    max-width: none !important;
  }

  .leftside-numbering {
    width: 0.8rem !important;
    font-size: 0.8rem !important;
  }

  h1.title {
    font-size: 1.7rem !important;
  }

  h2.title {
    margin-bottom: 0.5rem !important;
  }

  main.with-player {
    margin-bottom: 8rem !important;
  }

  .section-title:not(:first-of-type) {
    margin-top: 2rem !important;
  }

  .map-search-results {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (min-width: 421px) {
  .display-phone-only {
    display: none !important;
  }
}
