$alert-red: #c6867f;
$bg-red: #ffe4e1;
$error-red: #b65b5b;
$highlight-orange: #ff9d15;
$bg-blue: #cbf3ff;
$blue: #03BBF2;
$google-blue: #5385ed;
$highlight-blue: #ABD9FF;
$bg-gray: #f2f3f5;
$border-gray: #e3e3e3;
$control-gray: #f5f5f5;
$dark-gray: #3a3a3a;
$header-gray: #c7c8ca;
$navbar-gray: #d4d5d7;
$inactive-gray: #d9dbdc;
$text-gray: #6d6f71;
$title-gray: #515152;
$radius: 0.5rem;
