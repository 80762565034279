@use "./variables" as *;

.tag-badges-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
}

.tag-badge {
  font-size: 0.6rem;
  padding: 0.1rem 0.4rem;
  background-color: $text-gray;
  color: white;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: bold;
  white-space: nowrap;
}

.tag-details {
  font-size: 1.2rem;
}

#app-modal {
  .tag-badge {
    font-size: 1.2rem !important;
    padding: 0.2rem 0.6rem;
    display: inline-block;
  }
}

.dropdown-item {
  .tag-badge {
    font-size: 0.8rem !important;
    line-height: 1rem !important;
  }
}

.tag-badge:hover {
  background-color: $control-gray;
  color: $text-gray;
}

@media (hover: none) and (pointer: coarse) {
  .custom-tooltip {
    display: none !important;
  }
  .tag-badge {
    pointer-events: none;
  }
}
