@use "./variables" as *;

.close-btn {
  float: right;
  font-size: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.feedback {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  padding: 1rem;
  border-radius: $radius;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-out;
  font-size: 1.4rem;
  width: 20rem;
  animation: popOut 0.3s ease-in-out;
  overflow: hidden;

  &.notice {
    background-color: $bg-blue;
    border: 1px solid $blue;

    .progress-bar {
      background-color: $blue;
    }
  }

  &.alert {
    background-color: $bg-red;
    border: 1px solid $alert-red;

    .progress-bar {
      background-color: $alert-red;
    }
  }

  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: $header-gray;
    animation: progress 5s linear forwards;
    // margin: 0 -1rem -1rem -1rem;
  }
}

.notification {
  font-size: 1.3rem;
  padding: 1rem;
  border: 1px solid $inactive-gray;

  &.show-info {
    background-color: $bg-blue;
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border: none;
  }

  &.playlist-info {
    font-size: 1rem;
    background-color: white;
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  }

  .icon {
    position: relative;
    top: 0.2rem;
    margin-right: 0.5rem;
  }
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

@keyframes popOut {
  0% {
    transform: translateX(-50%) scale(0.8);
  }
  50% {
    transform: translateX(-50%) scale(1.2);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
