@use "./variables" as *;

#navbar-background {
  background-color: $navbar-gray;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.navbar {
  background: transparent;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  .navbar-start, .navbar-end {
    background-color: $navbar-gray;
  }

  .navbar-burger {
    color: $text-gray;
  }

  .navbar-brand {
    padding-left: 0.5rem;
    background-color: $navbar-gray;

    a:hover {
      background-color: transparent;
    }

    a:focus {
      outline: none;
      background-color: transparent;
    }

    a:focus-visible {
      outline: 2px solid $control-gray;
      background-color: transparent;
    }

    .site-logo {
      width: 220px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      margin-left: 17px;
    }
  }

  .login-btn {
    background-color: $control-gray;

    &:hover {
      background-color: $bg-gray;

      .icon {
        color: $text-gray;
      }
    }

    .icon {
      color: $inactive-gray;
      margin-right: 0.1rem !important;
    }
  }

  .dropdown-trigger button {
    width: 8rem;
    background-color: $control-gray
  }

  .dropdown-menu {
    border-radius: $radius !important;

    .icon {
      margin-right: 0.5rem !important;
      color: $text-gray;
      width: 1.2rem;
    }

    & a {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }

  .navbar-dropdown-label {
    font-size: 0.9rem;
    color: $text-gray;
    font-weight: bold;
    opacity: 0.8;
  }

  .search-term {
    width: 8rem;
    background-color: $control-gray;
  }

  .user-dropdown button {
    background-color: $inactive-gray;
    border: none;
  }

  .navbar-start {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-item {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1023px) {
    // Prevent user dropdown from going off right side of screen
    .navbar-end .dropdown-menu {
      right: 0;
      left: auto;
    }
  }

  @media (max-width: 1023px) {
    .navbar-end {
      justify-content: center;
      display: flex;
      width: 100%;

      .navbar-item {
        justify-content: center;
        width: 100%;
        display: flex;
      }
    }

    .site-logo {
      width: 180px !important;
    }

    .dropdown-menu {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 767px) {
    .navbar-start {
      flex-direction: column;
      align-items: stretch;
    }

    .navbar-item {
      justify-content: center;
      margin: none;
      padding: 0.5rem 0;
    }
  }

  @media (max-width: 420px) {
    .site-logo {
      width: 135px !important;
      margin-left: 10px !important;
    }
  }
}
