.mobile-apps img {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

@media (max-width: 1023px) {
  .sidebar-content {
    .mobile-apps {
      display: none;
    }
  }
}
