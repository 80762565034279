@use "./variables" as *;

.modal-content {
  background: white;
  padding: 1.5rem;
  border-radius: $radius;
  margin: 1rem auto auto auto;
  max-height: 80vh;
  max-width: 90vw;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  top: 5rem;

  &:focus {
    outline: none;
  }

  input, select {
    font-size: 1rem;
    border-radius: $radius;
    border-color: $border-gray;
    padding: 0.5rem;
  }

  button {
    font-size: 1rem;
    border: 1px solid $header-gray !important;
    border-radius: $radius;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .title {
    margin-bottom: 0.3rem !important;
  }
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 103;
  overflow-y: auto;
}

@media (min-width: 1023px) {
  .modal-content {
    min-width: 500px;
  }
}
