@use "./variables" as *;

.map-container {
  border-radius: $radius;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
}

.sidebar-content {
  .map-container {
    box-shadow: none !important;
  }
}

.show-badge {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.2rem;
  background-color: $text-gray;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  border-radius: 3px;
  padding: 0.1rem 0.3rem;
  color: white;
  display: inline-block;
  border: none !important;

  &:hover {
    background-color: $control-gray;
    color: $text-gray;
  }
}

.custom-marker-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: rgba($blue, 0.15);
  border: 2px solid $blue;
}

.map-search-results {
  height: 500px;
}

.map-popup {
  font-family: 'Open Sans Condensed', sans-serif;

  h2 {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  a {
    outline: none;
    border: none;
  }
}
