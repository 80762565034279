@use "./variables" as *;

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .error-logo {
    margin-bottom: 2.5rem;
  }

  .error-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: -0.5rem;
  }

  .error-subtitle {
    font-size: 2rem;
  }

  .error-detail {
    font-size: 2.5rem;
    color: $error-red;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
  }
}


